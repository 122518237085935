.container {
  margin: 0 auto;
  padding: 0 2.4rem;
  width: 100%;

  &.noMobilePadding {
    padding: 0;

    @media (--viewport-s) {
      padding: 0 2.4rem;
    }
  }

  &.noPadding {
    padding: 0;
  }
}

.small {
  max-width: var(--container-s);
}

.medium {
  max-width: var(--container-m);
}

.large {
  max-width: var(--container-l);
}

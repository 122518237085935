.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;

  &.center {
    align-items: center;
  }

  &.spaceBetween {
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }

  @media (--viewport-m) {
    align-items: center;
    flex-direction: row;
    gap: 1.6rem;
  }
}
